<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>¿Es esto un negocio?</h1>
      <p>
        La respuesta corta es: podría convertirse en uno, pero no lo es en este momento.
      </p>
      <p>
        Desde que compramos el terreno a fines de 2017, esto ha sido un sumidero de dinero. Se pagó una cantidad
        importante de dinero por la tierra, hay empleados de tiempo completo, los animales han sido comprados y
        alimentados con alimento comprado ya que la tierra es solo capaz de proporcionar suficiente para una fracción
        del año debido a una sequía de unos 6 meses cada año. No es que la tierra no es capaz de llevar animales y
        producir. Es que el terreno está dañado y lo estamos reparando.
      </p>
      <p>
        Los medios para financiar la operación en curso y la inversión provienen del trabajo en el sector de TI.
        Sin embargo, comíamos de la tierra. Hasta ahora hemos convertido algunos cerdos e incluso bovinos en comida para
        nosotros mismos. Actualmente, nuestro principal objetivo es llegar a <em>cero neto</em> en el balance.
      </p>

      <h2>Productos futuros</h2>
      <p>
        Para alcanzar el objetivo de <em>cero neto</em>, estamos buscando formas de ofrecer algunos productos sin
        contradiciendo de qué se trata en este proyecto. Es principalmente y ante todo un proyecto para crear abundancia
        en el suelo, la flora y fauna y hongos.
      </p>
      <p>
        Eso significa que cualquier producto que vayamos a tener será especial y escaso, todo dependiente
        en el desarrollo de la tierra de donde proviene.
      </p>

      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "BusinessES",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
